Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.applicationJsonContentType = "application/json";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.putApiMethod = "PUT";
exports.deleteApiMethod = "DELETE";
exports.surveyApiEndPoint = "bx_block_surveys/surveys";
exports.surveyQuestionApiEndPoint = "bx_block_surveys/questions";
exports.surveyQuestionSubmissionsApiEndPoint = "bx_block_surveys/submissions";
exports.surveyCatalogue = "Survey Catalogue";
exports.save = "Save";
exports.submit = "Submit";
exports.next = "Next";
exports.question = "Question";
exports.outOf = "out of";
exports.minValue = "Min Value";
exports.medianValue = "Median Value";
exports.maxValue = "Max Value";
exports.answer = "Answer";
exports.getUserDetails = "bx_block_user/users/edit_user";
exports.mandatoryQuestion = "This question is mandatory.\nPlease provide an answer";
exports.emptySpaceError = "No empty spaces allowed."
exports.exit = "Exit";
exports.close = "Close";
exports.exitSurvey = "Exit survey?";
exports.answerWillNotBeSaved = "Your answers will not be saved.";
exports.userSurveyListEndpoint = "bx_block_surveys/submissions/surveys";
exports.userProfileEndPoint = "bx_block_user/users/update_user";
exports.surveyGuide = "SURVEY GUIDE";
exports.surveyGuideDetail = "Filling in our surveys is easy and quick both in the application and on the web";
exports.updateSettings = "UPDATE SETTINGS";
exports.updateSettingsDetail = "Head to Settings, then easily update your details such as industry sector or local authority";
exports.contentFormData = "multipart/form-data";
// Customizable Area End