import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { ApiConstants, StoreConstantValues } from "../../../components/src/Constants";
import moment from "moment";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  loading:boolean;
  reportList: any[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfReportSurveyResultController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiReportId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      loading:false,
      reportList:[],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const dataMessage = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const successMessage = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorMessage = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    if (this.apiReportId !== null && this.apiReportId === dataMessage) {
      this.handleAPICallResponse(successMessage, errorMessage, this.postReportCallback);
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let buttonPressedMsg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    buttonPressedMsg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(buttonPressedMsg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount(): Promise<void> {
    
    this.getReport()
  }

  postReportCallback=(responseJson:any)=>{
     const {questions_list}=responseJson
     const reportRemainingQuestions=questions_list.filter((remainingQuestionObj:any) => remainingQuestionObj.data.type !== 'header');
     this.setState({reportList:reportRemainingQuestions,loading:false})
  }

  handleAPICallResponse=(successMsg: any, errorMsg: any, callback: Function)=>{
    if (errorMsg || successMsg.error) {
      this.setState({loading:false})
      return
    } else {
     callback(successMsg) 
    }
  }
  
  getReport=()=>{
    this.setState({loading:true})
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token:StoreConstantValues.USER_ACCESS_TOKEN
    };

    let surveyid = this.props.navigation.state.params.surveyId;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiReportId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),`${configJSON.reportEndPoint}/${surveyid}/${configJSON.surveyReportAPI}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      ApiConstants.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  getWidth=(widthString:string)=>{
   const getUpdatedWidth= widthString.split('%')
   const getNumWidth=parseInt(getUpdatedWidth[0])
   return getNumWidth

  }


  


   goBack=()=>{
    this.props.navigation.goBack()
   }



  goToProfile=()=>{
    this.props.navigation.navigate('Settings',{currentTab:0})
    StoreConstantValues.CURRENT_TAB='Profile'
  }

getWidthPercentage=(percent:number,multiplyThisBy:number)=>{
  return `${percent*multiplyThisBy/100}%`
}

getUserIndex=(Item:any,userArray:any)=>{
 const getUserIndex= userArray.findIndex((item: object | any)=>Item.option.id==item)
return getUserIndex+1??0
}

  getMomentDate = (data: object | any) => {
    return moment(data).format("DD/MM/YYYY")
  }

  getRadioAnswer = (data: object | any) => {
    return data.analytics.find((item: object | any) => item.optionId === parseInt(data.answer))
  }

  getBackgroundColor = (data: object | any, item: number) => {
    return data.includes(item.toString()) ? '#4B6EB0' : '#E4E8F2'
  }

  getTextColor = (data: object | any, item: number) => {
    return data.includes(item.toString()) ? '#fff' : '#202020'
  }
 
  // Customizable Area End
}
