import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

// Customizable Area End

import GroupsController, {
  Props,
  configJSON,
} from "./GroupsController";

export default class Groups extends GroupsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
   <></>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
