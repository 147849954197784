type Fonts = {
  regular: string;
  bold: string;

  medium: string;

  semiBold: string;
};

export const regex = {
  EMAIL_REGEX: /^\w+([\.\-\+]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
  FIRST_NAME_REGEX: /^[A-Za-z\s]+$/,
  LAST_NAME_REGEX: /^[A-Za-z]+$/,
  NO_SPACE_REGEX: /^(\s+|\s+)$/,
  SPLASH_REGEX: /[?&]([^=#]+)=([^&#]*)/g,
  SPLASH_REPLACE_REGEX: /.*?:\/\//g,
  PASSWORD_REGEX: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{10,}$/,
};

export const FontFamily: Fonts = {
  regular: 'Aeonik-Regular',
  bold: 'Aeonik-Bold',

  medium: 'Aeonik-Medium',

  semiBold: 'Aeonik-Medium',
};

export const ApiConstants = {
  GET: 'GET',
  POST: 'POST',
  PATCH: 'PATCH',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

export const StoreConstantValues = {
  USER_ACCESS_TOKEN: '',
  USER_ID: '',
  FIRST_NAME: '',
  LAST_NAME: '',
  IMAGE: '',
  CURRENT_TAB: 'Survey',
  DRAWER_OPEN: false,
  IS_TUTORIAL_COMPLETED: false,
};
