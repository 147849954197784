import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
export interface Group{
  name:string;
  description:string;
  group_name:string | undefined | null;
  id:number;
}

export interface GroupObj{
  item:Group;
  index:number;
}

export interface UserItem{
  email: string;
  group_ids:string[]|number[]|null;
  user_id: string|number;
  name:string;
}
export interface UserObj{
  item:UserItem,
  index:number
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  groupName:string;
  groupDescription:string;
  showCreateGroupModal:boolean;
  groupList:Group[];
  isGroups:boolean;
  showCreateUserModal:boolean;
  name:string;
  email:string;
  userList:UserItem[];
  loading:boolean;
  groupPage:number;
  hasMoreGroup:boolean;
  userPage:number;
  hasMoreUser:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class GroupsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getGroupsId:string='';
  createGroupId:string='';
  getUserListId:string='';
  createUserId:string='';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      groupName:'',
      groupDescription:'',
      showCreateGroupModal:false,
      groupList:[],
      isGroups:true,
      showCreateUserModal:false,
      name:'',
      email:'',
      userList:[],
      loading:false,
      groupPage:1,
      hasMoreGroup:false,
      userPage:1,
      hasMoreUser:false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const dataMessage = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const successMessage = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorMessage = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
    if (this.getGroupsId !== null && this.getGroupsId === dataMessage) {
    this.handleGetGroupsResponse(successMessage,errorMessage)
    }
    else if (this.createGroupId !== null && this.createGroupId === dataMessage) {
      this.handleCreateGroupResponse(successMessage,errorMessage)
      } 
      else if (this.getUserListId !== null && this.getUserListId === dataMessage) {
        this.handleUserListResponse(successMessage,errorMessage)
        } 
        else if (this.createUserId !== null && this.createUserId === dataMessage) {
          this.handleAddUserResponse(successMessage,errorMessage)
      }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();

    this.getGroups()
    
  }
  handleAddUserResponse=(successMessage:{users:UserItem,errors:string[]},errorMessage:string)=>{
  
    if(successMessage && !successMessage.errors)
    {
      this.setState({
userPage:1
      },()=>{
        this.getuserList(1)
      })
      
    }
    else
    {
      this.setState({loading:false})
      let errorText=''
      successMessage.errors.forEach(errorItem=>{errorText+=`${errorItem}. `})
      this.showAlert('Error',errorMessage??errorText)
    }
      }

  handleUserListResponse=(successMessage:{users:UserItem[],total_count:number},errorMessage:string)=>{
    const {userList,userPage}=this.state
if(successMessage)
{

  const lengthCheck=userList.length+successMessage.users.length<successMessage.total_count
  const pageCheck = userPage!==1
    this.setState({
      userList:userPage==1?successMessage.users:[...userList,...successMessage.users],
      loading:false,
      hasMoreUser:pageCheck ? lengthCheck : true
    })
}
else
{
  this.setState({loading:false})
  this.showAlert('Error',errorMessage)
}
  }

  handleGetGroupsResponse=(successMessage:{groups:Group[],total_count:number},errorMessage:string)=>{
    const {groupList,groupPage}=this.state
    if(successMessage)
    {
      const lengthCheck=groupList.length+successMessage.groups.length<successMessage.total_count
      const pageCheck = groupPage!==1
        this.setState({
          groupList:groupPage==1?successMessage.groups:[...groupList,...successMessage.groups],
          loading:false,
          hasMoreGroup:pageCheck ? lengthCheck : true
        })
    }
    else{
      this.setState({loading:false})
      this.showAlert('Error',errorMessage)
    }
  }

  handleCreateGroupResponse=(successMessage:{error:string[]},errorMessage:string)=>{
    if(successMessage && !successMessage.error)
    {this.setState({
      groupPage:1
    },()=>{
this.getGroups(1)
    })}
    else{
      let errorText=''
      successMessage.error.forEach(errorItem=>{errorText+=`${errorItem}. `})
      this.setState({loading:false})
      this.showAlert('Error',errorMessage??errorText)
    }
  }

  getGroups=(forPage:number=0)=>{

    this.setState({
      loading: true,
    });
    const {groupPage}=this.state

    const header = {
      "Content-Type": configJSON.applicationJSONcontentType,
      "token":"eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MzgzLCJleHAiOjE2OTE3MzU2ODMsInRva2VuX3R5cGUiOiJsb2dpbiJ9.DF_9vx7tBx4_67cVxZBGHf6YHu_uM9ScfbwU1Td6zblpyuTh8hRQxpzEYwKCkCmiRckS2oGCF49JmSVKo59ZVw"
    };

    const getPage=forPage===1?forPage:groupPage

    const params = `?page=${getPage}&per_page=8`

    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getGroupsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.groupListEndPoint+params
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  onGroupNameChange=(text:string)=>{
    this.setState({groupName:text})
}
onGroupDescriptionChange=(text:string)=>{
    this.setState({groupDescription:text})
}

pressCreate=()=>{
  this.setState({loading:true,showCreateGroupModal:false,groupName:'',groupDescription:''})
this.createGroup()
}

createGroup =()=>{
  
  const header = {
    "Content-Type": configJSON.applicationJSONcontentType,
    "token":"eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MzgzLCJleHAiOjE2OTE3MzU2ODMsInRva2VuX3R5cGUiOiJsb2dpbiJ9.DF_9vx7tBx4_67cVxZBGHf6YHu_uM9ScfbwU1Td6zblpyuTh8hRQxpzEYwKCkCmiRckS2oGCF49JmSVKo59ZVw"
  };

  const body = {
    name:this.state.groupName,
    description:this.state.groupDescription
  }
  
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.createGroupId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.createGroupEndpoint
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    'POST'
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);

  return true;
}

toggleModal=()=>{
    const {showCreateGroupModal}= this.state
    this.setState({showCreateGroupModal:!showCreateGroupModal})
}

userListTabPressed=()=>{
  this.setState({isGroups:false,groupPage:1,hasMoreGroup:false})
  this.getuserList()
}

getuserList=(forPage:number=0)=>{
  this.setState({
    loading: true,
  });
  const header = {
    "Content-Type": configJSON.applicationJSONcontentType,
    "token":"eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MzgzLCJleHAiOjE2OTE3MzU2ODMsInRva2VuX3R5cGUiOiJsb2dpbiJ9.DF_9vx7tBx4_67cVxZBGHf6YHu_uM9ScfbwU1Td6zblpyuTh8hRQxpzEYwKCkCmiRckS2oGCF49JmSVKo59ZVw"
  };
  const {userPage}=this.state
  const getPage=forPage===1?forPage:userPage

  const params = `?page=${getPage}&per_page=22`

  
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getUserListId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.userListEndpoint+params
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    'GET'
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);

  return true;
}

groupsTabPressed=()=>{
  this.setState({isGroups:true,userPage:1,hasMoreUser:false})
  this.getGroups()
}

editPressed=(groupId:number)=>{
  this.props.navigation.navigate('UserList',{groupId:groupId})
}

toggleCreateUserModal=()=>{
  const {showCreateUserModal}=this.state
  this.setState({
    showCreateUserModal:!showCreateUserModal
  })
}

onNameChange=(text:string)=>{
  this.setState({name:text})
}

onEmailChange=(text:string)=>{
  this.setState({email:text})
}


onCreateUserPress=()=>{
  const header = {
    "Content-Type": configJSON.applicationJSONcontentType,
    "token":"eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MzgzLCJleHAiOjE2OTE3MzU2ODMsInRva2VuX3R5cGUiOiJsb2dpbiJ9.DF_9vx7tBx4_67cVxZBGHf6YHu_uM9ScfbwU1Td6zblpyuTh8hRQxpzEYwKCkCmiRckS2oGCF49JmSVKo59ZVw"
  };

 const body = {
  name:this.state.name,
   email:this.state.email
 }
  
  const newRequestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.createUserId = newRequestMessage.messageId;
  newRequestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.createUserEndPoint
  );

  newRequestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  newRequestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)
  );



  newRequestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    'POST'
  );
  
  runEngine.sendMessage(newRequestMessage.id, newRequestMessage);  
  this.setState({showCreateUserModal:false,loading:true})
}

loadGroupMore=()=>{
  const {loading,hasMoreGroup}=this.state
  if (!loading && hasMoreGroup ) {
  this.setState(prevState => ({
    groupPage: prevState.groupPage + 1,
    loading: true
  }), () => {
   
    this.getGroups();
  });
}
}

loadUserMore=()=>{
  const {loading,hasMoreUser}=this.state
  if (!loading && hasMoreUser ) {
  this.setState(prevState => ({
    userPage: prevState.userPage + 1,
    loading: true
  }), () => {
    this.getuserList();
  });
}
}

  // Customizable Area End
}
